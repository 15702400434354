//
// Base
//

// Body
body {
	background: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}

.form-wrap.form-builder .frmb .form-elements label:first-child{
	text-transform: none !important;
}
