.login.login-4 .login-signin,
.login.login-4 .login-signup,
.login.login-4 .login-forgot {
  display: none; }

.login.login-4.login-signin-on .login-signup {
  display: none; }

.login.login-4.login-signin-on .login-signin {
  display: block; }

.login.login-4.login-signin-on .login-forgot {
  display: none; }

.login.login-4.login-signup-on .login-signup {
  display: block; }

.login.login-4.login-signup-on .login-signin {
  display: none; }

.login.login-4.login-signup-on .login-forgot {
  display: none; }

.login.login-4.login-forgot-on .login-signup {
  display: none; }

.login.login-4.login-forgot-on .login-signin {
  display: none; }

.login.login-4.login-forgot-on .login-forgot {
  display: block; }

.login.login-4 .login-form {
  width: 100%;
  max-width: 450px;
  background: #fff;
  border-radius: 15px;
  padding: 30px;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.31);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.31);
  box-shadow: 0px 0px 14px 0px rgba(0,0,0,0.31);;
}

.invalid-feedback {
  text-align: left;
}

label.required-input:after {
  content: ' * ';
  color: #f00;
}

.form-control.is-invalid {
  border-color: $danger !important;

}


@media (max-width: 575.98px) {
  .login.login-4 .login-form {
    width: 100%;
    max-width: 100%; } }
