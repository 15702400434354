//
// Keenthemes Icons
//

@mixin ki($icon) {
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-variant: normal;
    line-height: 1;
    text-decoration: inherit;
    text-rendering: optimizeLegibility;
    text-transform: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    content: "#{$icon}";
}
